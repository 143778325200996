import React, { Component } from "react";
import axios from "axios";
import DatePicker from "material-ui/DatePicker";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import IconButton from "material-ui/IconButton";
import Toggle from "material-ui/Toggle";
import Checkbox from "material-ui/Checkbox";
import { observer, inject } from "mobx-react";
import LinearProgress from "material-ui/LinearProgress";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import Divider from "material-ui/Divider";
import ContentClear from "material-ui/svg-icons/content/clear";
import moment from "moment";

import { divProgressHide, divProgressShow } from "../../css/additional.js";

import {
  authPw,
  authUser,
  parentsUrl,
  getCurrentChild,
  getCurrentOrg,
  getAllPlans,
  getFutureChildPlans,
  getSpecials,
  getCurrentInvoice,
  getAllOrg,
  invoicesUrl,
  api2ChildSendInvitationToCabinet,
  api2ChildSendFutureInvoice,
  api2ChildTransferParentsToSchool,
  api2ChildShow, api2ClassesByOrganizationId,
  api2ChildVaccineRecords
} from "../../utils/operations.js";

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table";

import { blue600, grey400 } from "material-ui/styles/colors";
import {
  api2ChildDelete,
  api2ChildGetFutureInvoice, api2ChildLogFinance,
  api2ChildPatch,
  api2ChildUpdateInvoice
} from "../../utils/operations";

import API from '../../utils/api';
import { toast } from 'react-toastify';
import appRoutes from "../../utils/appRoutes";
import {ExpandedField} from "../../components/ExpandedField";

const AriaModal = require("react-aria-modal");

class EditChild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: "",
        photo: "",
        photofile: "",
        firstname: "",
        lastname: "",
        organisation: "",
        classes: "",
        parent: "",
        birthday: new Date(),
        programs: "",
        _created: null,
        startdate: new Date(),
        dropdate: null,
        status: "",
        parent_id: "",
        usephoto: false,
        acs: false,
        memo: "",
        afterschool: "",
        futuredepo: 0,
        nextyeardepo: 0
      },
      futuredepoOriginal: 0,
      nextyeardepoOriginal: 0,
      datasource: [],
      plans: [],
      plansChanged: false,
      organisations: [],
      parents: [],
      emergency: [],
      medicalRecords: [],
      vaccineRecords: [],
      specials: [],
      programs: [],
      classes: [],
      currentOrgClasses: [],
      billParent: "",
      opendialog: false,
      texterrors: "",
      showProgress: true,
      showParents: false,
      showEmergency: false,
      showSpecials: false,
      showMedicalRecords: false,
      test: "hide",
      changeSchool: 1,
      changeClass: null,
      gotOrgClasses: [],
      gotClasses: [],
      showFutureInvoice: false,
      pdfFile: this.props.Auth.pdfFile,
      invoiceData: null,
      invoiceId: null,
      programFilterValue: '',
      showTransferModal: false,
      transferWitParents: true,
    };
  }

  handleOpen = () => {
    this.setState({ opendialog: true });
  };

  handleClose = () => {
    this.setState({ showProgress: false });
    this.setState({ opendialog: false });
  };

  componentDidMount() {
    if (!this.props.Auth.child_id) {
      this.props.Auth.child_id = this.props.match.params.id;
    }
    this.setState({ changeSchool: this.props.Auth.orgId });
    this.loadData();
    this.forceUpdate();
  }

  componentWillUnmount() {
    this.props.Auth.pdfFile = "";
  }

  loadData = () => {
    this.setState({ showProgress: true });
    let orgId = this.props.Auth.orgId;
    let childId = this.props.match.params.id;

    let that = this;

    axios
      .all([
        API.get(api2ChildShow(childId), {
          params: {
            with: 'emergency,medicalRecords,vaccineRecords',
          }
        }),
        API.get(getCurrentOrg + orgId),
        API.get(api2ClassesByOrganizationId(orgId)),
        API.get(getAllOrg + "?related=classes_by_organisation_id"),
        API.get(invoicesUrl + `?fields=id%2Cpaid&filter=child_id%3D${childId}&limit=1`),
        API.get(api2ChildVaccineRecords(childId))
      ])
      .then(
        axios.spread(function(
          child,
          organisations,
          classes,
          allschools,
          invoice,
          vaccine_records
        ) {
          const data = child.data.data;
          const vaccineRecords = vaccine_records.data.data;
          const organisation = organisations.data;
          const gotClasses = classes.data.data;
          const gotOrgClassesRaw = allschools.data.resource;
          const invoiceData = invoice.data.resource.length > 0 ? invoice.data.resource[0] : null;
          const invoiceId = invoiceData ? invoiceData.id : null;
          const changeClass = gotClasses.length ? gotClasses[0].id : 0;

          const currentOrgClasses = gotOrgClassesRaw.find((org) => org.id === data.organisation_id).classes_by_organisation_id

          data.organisation = organisation.name;
          data.orgID = organisation.id;

          data._created
            ? (data._created = new Date(data._created))
            : (data._created = null);
          data.startdate
            ? (data.startdate = new Date(data.startdate))
            : (data.startdate = null);
          data.dropdate
            ? (data.dropdate = new Date(data.dropdate))
            : (data.dropdate = null);
          data.birthday
            ? (data.birthday = new Date(data.birthday))
            : (data.birthday = null);

          that.setState({
            data: data,
            futuredepoOriginal: data.futuredepo,
            nextyeardepoOriginal: data.nextyeardepo,
            organisations: gotOrgClassesRaw,
            showProgress: false,
            classes: gotClasses,
            gotClasses,
            changeClass: changeClass,
            currentOrgClasses: currentOrgClasses,
            invoiceData: invoiceData,
            invoiceId,
            emergency: data.emergency,
            medicalRecords: data.medical_records,
            vaccineRecords: vaccineRecords,
          });
          that.forceUpdate();
        })
      )
      .catch(function(error) {
        toast.error('Some thing wrong!' + error);
      });

    this.loadPlans(childId);
    this.loadParents(childId);
    this.loadSpecials(childId);

    this.setState({ showProgress: false });
  };

  loadParents = childId => {
    let that = this;
    let ctp = getCurrentChild + childId + "?related=parents_by_childtoparent";

    API.get(ctp)
      .then(res => {
        let parents = res.data.parents_by_childtoparent;
        that.setState({ parents });
      });
  };

  loadPlans = async itemId => {
    let [childplans, allplans] = await axios.all([
      API.get(getFutureChildPlans + "?filter=child_id%3D" + itemId),
      API.get(getAllPlans)
    ]);

    let newplans = allplans.data.resource;
    let currentPlans = childplans.data.resource;
    let plans = [];
    let toggled = false;

    for (let i = 0; i < newplans.length; i++) {
      for (let j = 0; j < currentPlans.length; j++) {
        if (newplans[i].id === currentPlans[j].plan_id) {
          toggled = true;
        }
      }

      plans.push({
        id: newplans[i].id,
        name: newplans[i].name,
        price: newplans[i].price,
        sort_score: newplans[i].sort_score,
        toggled: toggled
      });

      toggled = false;
    }

    plans.sort(function(a, b) {
      return a.sort_score - b.sort_score;
    });

    this.setState({
      plans,
      currentPlans
    });

    this.forceUpdate();
  };

  loadSpecials = childId => {
    let url = getSpecials + "/?filter=child_id%3D" + childId.toString();

    API.get(url)
      .then(res => {
        let specials = res.data.resource;
        if (specials.length > 0) {
          this.setState({ specials });
        }
      });
  };


  deleteData = async () => {
    this.setState({ opendialog: false });
    let that = this;
    let child_id = this.state.data.id;

    try {
      await API.delete(api2ChildDelete(child_id))
      toast.success('Child deleted successfully!');
      that.props.history.push("/children");
    } catch (e) {
      toast.error('Some thing wrong!');
    }

  };

  deleteCurrentBill = async () => {
    await API.delete(getCurrentInvoice + this.state.invoiceId.toString())
  };

  saveData = async () => {
    this.setState({ showProgress: true });
    let child = this.state.data;

    child.startdate
      ? (child.startdate = moment(child.startdate).format("YYYY-MM-DD"))
      : (child.startdate = null);
    child.birthday
      ? (child.birthday = moment(child.birthday).format("YYYY-MM-DD"))
      : (child.birthday = null);

    if (child.dropdate) {
      let compareDate =
        moment(child.dropdate).format("YYYY-MM-DD") <=
        moment().format("YYYY-MM-DD");
      if (compareDate && this.state.invoiceId && this.state.invoiceData.paid === 0) {
        await this.deleteCurrentBill();
      }

      child.dropdate = moment(child.dropdate).format("YYYY-MM-DD");
    } else {
      child.dropdate = null;
    }

    let data = {
      firstname: child.firstname,
      lastname: child.lastname,
      organisation_id: child.organisation_id,
      parent_id: child.parent_id,
      classes_id: child.classes_id,
      birthday: child.birthday,
      startdate: child.startdate,
      dropdate: child.dropdate,
      usephoto: child.usephoto,
      afterschool: child.afterschool,
      status: child.status,
      acs: child.acs,
      futuredepo: child.futuredepo,
      nextyeardepo: child.nextyeardepo,
      memo: child.memo
    };

    try {
      const futuredepoDiff = +child.futuredepo - this.state.futuredepoOriginal
      const nextyeardepoDiff = +child.nextyeardepo - this.state.nextyeardepoOriginal

      await API.patch(api2ChildPatch(child.id), data)

      if (futuredepoDiff > 0) {
        await API.post(api2ChildLogFinance(child.id), { amount: futuredepoDiff, type: 'futuredepo' })
      }

      if (nextyeardepoDiff > 0) {
        await API.post(api2ChildLogFinance(child.id), { amount: nextyeardepoDiff, type: 'nextyeardepo' })
      }
    } catch (error) {
      toast.error(error.response.data.error)
      return false;
    }

    if (this.state.plansChanged) {
      let plans = [...this.state.plans];

      let resource = plans
        .filter(p => p.toggled)
        .map(p => {return {plan_id: p.id, child_id: this.state.data.id}})

      if (resource.length === 0) {
        resource.push({ child_id: this.state.data.id, plan_id: 1 });
      }


      try {
        await API.delete(getFutureChildPlans + "?filter=child_id%3D" + this.state.data.id)
        await API.post(getFutureChildPlans, { resource: resource })
      } catch (error) {
        let issues = error.response.data._issues;
        let textErrors = "";

        for (let key in issues) {
          textErrors += key + ": " + issues[key] + "\n";
        }
        toast.error(textErrors)
      }
    }

    toast.success('Child edited successfully!');
    setTimeout(this.props.history.push("/children"), 1000);
  };

  usePhoto = () => {
    let data = this.state.data;
    data.usephoto = !data.usephoto;
    this.setState({ data });
  };

  setACS = () => {
    let data = this.state.data;
    data.acs = !data.acs;
    this.setState({ data });
  };

  handleAfterschool = e => {
    let data = this.state.data;
    data.afterschool = e.target.value;
    this.setState({ data });
  };

  handleOrgChange = (event, index, value) => {
    const data = this.state.data;
    data.organisation = value;
    data.classes = "";
    this.setState({ data });

    const currentOrgClasses = this.state.organisations[index].classes_by_organisation_id;
    this.setState({ currentOrgClasses });

    axios
      .get(parentsUrl + '/?where={"organisation_id":' + value + "}", {
        withCredentials: true,
        auth: {
          username: authUser,
          password: authPw
        }
      })
      .then(res => {
        let ds = res.data._items;
        let datasource = [];
        let data = {};
        for (let i = 0; i < ds.length; i++) {
          data = { name: ds[i].firstname + " " + ds[i].lastname, id: ds[i].id };
          datasource.push(data);
        }
        this.setState({ datasource });
      });
  };

  handleClassChange = (event, index, value) => {
    const data = this.state.data;
    data.classes_id = value;
    this.setState({ data });
  };

  handleChange = event => {
    const data = this.state.data;
    data[event.target.id] = event.target.value;
    this.setState({ data });
  };

  handleBirthday = (event, birthday) => {
    const data = this.state.data;
    data.birthday = birthday;
    this.setState({ data });
  };

  handleStartDate = (event, startDate) => {
    const data = this.state.data;
    data.startdate = startDate;
    this.setState({ data });
  };

  handleDropDate = (event, dropDate) => {
    const data = this.state.data;
    data.dropdate = dropDate;
    this.setState({ data });
  };

  goPreviousPage = () => {
    this.props.Auth.child_id = null;
    setTimeout(this.props.history.push("/children"), 1000);
  };

  handleToggle = e => {
    let plans = this.state.plans;
    let name = e.target.name;

    for (let i = 0; i < plans.length; i++) {
      if (plans[i].id === Number(name)) {
        plans[i].toggled = !plans[i].toggled;
      }
    }

    this.setState({ plans, plansChanged: true });
  };

  handleRemoveDropDate = () => {
    const data = this.state.data;
    data.dropdate = null;
    this.setState({ data });
  };

  addParent = () => {
    this.props.Auth.addParentToChildId = this.state.data.id;
    const newUrl = "/newparent";
    setTimeout(this.props.history.push(newUrl), 1000);
  };

  addEmergency = () => {
    const newUrl = "/editemergency/:new";
    setTimeout(this.props.history.push(newUrl), 1000);
  };

  addMedicalRecord = () => {
    const childId = this.props.Auth.child_id;
    const newUrl = appRoutes.addMedicalRecord(childId)
    setTimeout(this.props.history.push(newUrl), 1000);
  };

  addVaccineRecord = () => {
    const childId = this.props.Auth.child_id;
    const newUrl = appRoutes.childAddVaccineRecord(childId)
    setTimeout(this.props.history.push(newUrl), 1000);
  };

  editSpecials = () => {
    const childId = this.props.Auth.child_id;
    const newUrl = appRoutes.editSpecialNeeds(childId)
    setTimeout(this.props.history.push(newUrl), 1000);
  };

  editParent = id => {
    const editUrl = "/editparent/" + id;
    setTimeout(this.props.history.push(editUrl), 1000);
  };

  editEmergency = id => {
    const editUrl = "/editemergency/:" + id;
    setTimeout(this.props.history.push(editUrl), 1000);
  };

  editMedicalRecord = id => {
    const childId = this.props.Auth.child_id;
    const editUrl = appRoutes.editMedicalRecord(childId, id);
    setTimeout(this.props.history.push(editUrl), 1000);
  };

  editVaccineRecord = (vaccineRecordId, childVaccineRecordId) => {
    const childId = this.props.Auth.child_id;
    const editUrl =  childVaccineRecordId ? appRoutes.childEditVaccineRecord(childId, childVaccineRecordId)
      : appRoutes.childAddVaccineRecord(childId, vaccineRecordId);
    setTimeout(this.props.history.push(editUrl), 1000);
  };

  refreshInvoice = async () => {
    const childId = this.props.Auth.child_id;

    if (!window.confirm('Are you sure ? \nThis action will regenerate invoice with applied changes!')) return;

    try {
      const result = await API.get(api2ChildUpdateInvoice(childId))
      toast.success("Invoice was updated successfully")
      setTimeout(() => {
        window.open(result.data.file)
      }, 1000);
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    }
  };

  sendInvitation = async () => {
    const childId = this.props.Auth.child_id;

    if (!window.confirm('Are you sure ? \nAll parents of this child will receive invitations!')) return;

    try {
      await API.get(api2ChildSendInvitationToCabinet(childId))
      toast.success("Invitation was sent successfully")
    } catch (e) {
      toast.error(e.response.data.error)
    }
  };

  sendFutureInvoice = async () => {
    if (!window.confirm('This action will generate and send an invoice to the parent(s). \nAre you sure?')) return;

    try {
      const childId = this.props.Auth.child_id
      await API.get(api2ChildSendFutureInvoice(childId))
      toast.success("Invoice was sent successfully")
    } catch (e) {
      toast.error(e.response.data.error)
    }
  };

  viewFutureInvoice = async () => {
    const childId = this.props.Auth.child_id

    try {
      const result = await API.get(api2ChildGetFutureInvoice(childId))
      setTimeout(() => {
        window.open(result.data.file)
      }, 100);
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    }
  };

  handleTransferClick = () => {
    if (!this.state.changeClass) {
      return toast.error("Please, select class.")
    }

    this.setState({ showTransferModal: true })
  }

  transferChildApi2 = async () => {
    const childId = this.props.match.params.id
    const data = {
      organisation_id: this.state.changeSchool,
      classes_id: this.state.changeClass,
    }

    try {
      await API.patch(api2ChildPatch(childId), data)

      if (this.state.transferWitParents) {
        await API.post(api2ChildTransferParentsToSchool(childId), {organisation_id: this.state.changeSchool})
      }

      toast.success("Child transferred successfully!")
    } catch (e) {
      toast.error("Something wrong. Please try again.")
    }

    this.props.Auth.setOrgId(this.state.changeSchool)
    this.props.Auth.getOrgName()

    setTimeout(this.props.history.push("/children"), 1000)
  }

  handleChangeOrg = (event, index, value) => {
    let organisations = this.state.organisations;
    let gotClasses = organisations.find((org) => org.id === +value).classes_by_organisation_id;
    this.setState({ changeSchool: value, changeClass: '', gotClasses });
  };

  // Обработка Memo
  handleMemo = event => {
    const data = this.state.data;
    data.memo = event.target.value;
    this.setState({ data });
  };

  render() {
    const transferModal = this.state.showTransferModal ? (
      <AriaModal
        titleText="Child Transfer Confirmation"
        getApplicationNode={() => document.getElementById('root')}
        underlayStyle={{ paddingTop: '2em', zIndex: 1500 }}
        onExit={() => this.setState({ showTransferModal: false })}
        focusDialog
      >
        <div
          style={{
            background: "#fff",
            outline: 0,
            minWidth: 450,
            maxWidth: 700,
            padding: 50,
            borderRadius: 4
          }}
        >
          <h2>Child Transfer Confirmation</h2>
          <div style={{ fontSize: '18px', marginBottom: '30px' }}>
            Are you sure ?
            <br />This will transfer child to another school and class!
            <br /><br />Please, recreate invoice if need after this action.
            <br />
            <br />
            <Checkbox
              label="Transfer Parents to New School"
              style={{ marginBottom: 16 }}
              defaultChecked={this.state.transferWitParents}
              onCheck={() => this.setState({ transferWitParents: !this.state.transferWitParents})}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <RaisedButton
              label="Confirm"
              primary
              onClick={this.transferChildApi2}
            />
            <RaisedButton
              label="Close"
              onClick={() => this.setState({ showTransferModal: false })}
            />
          </div>

        </div>
      </AriaModal>
    ) : null;

    const actions = [
      <FlatButton key='close-action' label="Cancel" primary={true} onClick={this.handleClose} />,
      <FlatButton key='delete-action' label="OK" primary={true} onClick={this.deleteData} />
    ];

    return (
      <div className="child">
        <br />
        <div style={this.state.showProgress ? divProgressShow : divProgressHide}>
          <LinearProgress mode="indeterminate" />
        </div>
        <div>
          <span>Select school transfer to:</span>
          <br />
          <div className="childTransfer">
            <SelectField
              value={this.state.changeSchool}
              onChange={this.handleChangeOrg}
              fullWidth
            >
              {Object.values(this.props.Auth.allOrgs).map((org) => {
                return <MenuItem key={org.id} value={org.id.toString()} primaryText={org.name} />
              })}
            </SelectField>
            <SelectField
              value={this.state.changeClass}
              onChange={(e, i, v) => {
                this.setState({ changeClass: v });
              }}
              fullWidth
            >
              {this.state.gotClasses.map(row => (<MenuItem key={row.id} value={row.id} primaryText={row.name} />))}
            </SelectField>
            <div>
              <RaisedButton label="Transfer" onClick={this.handleTransferClick} />
            </div>
          </div>
        </div>
        <br />
        <br />

        <ExpandedField title='Parents' onAddClick={this.addParent}>
          {this.state.parents.map(row => (
            <div key={row.id}>
              <FlatButton
                label={row.firstname + " " + row.lastname}
                primary={true}
                onClick={() => this.editParent(row.id)}
              />
              <br />
              <span style={{ color: grey400 }}>Email: </span>{" "}
              <span>{row.email}</span>
              <br />
              <span style={{ color: grey400 }}>Phone 1: </span>{" "}
              <span>{row.phone}</span>
              <br />
              <span style={{ color: grey400 }}>Phone 2: </span>{" "}
              <span>{row.phone2}</span>
              <br />
              <span style={{ color: grey400 }}>Phone 3: </span>{" "}
              <span>{row.phone3}</span>
              <br />
              {row.organisation_id !== this.state.data.organisation_id && <div className='text--error'>Different organisation! Please fix!</div>}
              <br />
            </div>
          ))}
        </ExpandedField>

        <ExpandedField title='Emergency contacts' onAddClick={this.addEmergency}>
          {this.state.emergency.map(row => (
            <p key={row.id}>
              <FlatButton
                label={row.name + ' - ' + (row.relation ? row.relation : '')}
                primary={true}
                onClick={() => this.editEmergency(row.id)}
              />
              <br />
              <span style={{ color: grey400 }}>Phone: </span>{" "}
              <span>{row.phone}</span>
              <br />
            </p>
          ))}
        </ExpandedField>

        <ExpandedField title='Allergies and Special needs'>
          {this.state.specials.map(row => (
            <p key={row.id}>
              <span style={{ color: grey400 }}>Allergies: </span>{" "}
              <span>{row.allergies}</span>
              <br />
              <span style={{ color: grey400 }}>Special needs: </span>{" "}
              <span>{row.additionally}</span>
            </p>
          ))}
          <FlatButton label="Edit Additional Info" primary onClick={this.editSpecials} />
        </ExpandedField>

        <ExpandedField title='Medical Records' onAddClick={this.addMedicalRecord}>
          <Table>
            <TableBody displayRowCheckbox={false}>
              {this.state.medicalRecords.map(row => (
                <TableRow key={row.id} onMouseDown={() => this.editMedicalRecord(row.id)} style={{cursor: 'pointer'}}>
                  <TableRowColumn>{row.title}</TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'center' }}>{row.expiration_date}</TableRowColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ExpandedField>
        <ExpandedField title='Vaccine Records'>
          <Table>
            <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
              <TableRow>
                <TableRowColumn>Title</TableRowColumn>
                <TableRowColumn style={{ textAlign: 'center' }}>Doses completed</TableRowColumn>
                <TableRowColumn style={{ textAlign: 'center' }}>Expiration date</TableRowColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {this.state.vaccineRecords.map(row => (
                <TableRow
                  key={row.vaccine_record_id}
                  onMouseDown={() => this.editVaccineRecord(row.vaccine_record_id, row.child_vaccine_record_id)}
                  style={{cursor: 'pointer'}}
                >
                  <TableRowColumn>{row.vaccine_title}</TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'center' }}>{row.doses_completed ?? '-'}</TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'center' }}>{row.expiration_date ?? '-'}</TableRowColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ExpandedField>

        <br />
        <Divider />
        <br />
        {this.props.Auth.role === "superadmin" || this.props.Auth.show_finance ? (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            <RaisedButton
              label="View Future Invoice"
              onClick={this.viewFutureInvoice}
            />
            <RaisedButton
              label="Send Future Invoice"
              onClick={this.sendFutureInvoice}
            />
            <RaisedButton
              label="Create New Invoice"
              onClick={this.refreshInvoice}
            />
            <RaisedButton
              label="Send Payment Invite"
              onClick={this.sendInvitation}
            />
          </div>
        ) : null}
        <div className="childForm">
          <TextField
            id="firstname"
            hintText="First Name"
            floatingLabelText="First Name"
            errorText={this.state.data.firstname === "" && "This field is required"}
            fullWidth={true}
            value={this.state.data.firstname}
            onChange={this.handleChange}
          />

          <TextField
            id="lastname"
            hintText="Last Name"
            floatingLabelText="Last Name"
            errorText={this.state.data.lastname === "" && "This field is required"}
            fullWidth={true}
            value={this.state.data.lastname}
            onChange={this.handleChange}
          />

          <SelectField
            id="organisation"
            floatingLabelText="School"
            errorText={this.state.data.organisation_id === "" && "This field is required"}
            fullWidth
            value={this.state.data.organisation_id}
            onChange={this.handleOrgChange}
            disabled={true}
          >
            {this.state.organisations.map(row => (
              <MenuItem key={row.id} value={row.id} primaryText={row.name} />
            ))}
          </SelectField>

          <SelectField
            id="classes"
            floatingLabelText="Class"
            errorText={this.state.data.classes_id === "" && "This field is required"}
            fullWidth
            value={this.state.data.classes_id}
            onChange={this.handleClassChange}
          >
            {this.state.currentOrgClasses.map(rows => (
              <MenuItem key={rows.id} value={rows.id} primaryText={rows.name} />
            ))}
          </SelectField>

          <DatePicker
            id="created"
            hintText="Created on"
            floatingLabelText="Created on"
            container="inline"
            textFieldStyle={{ width: "100%" }}
            value={this.state.data._created}
            disabled
            locale="en-US"
            firstDayOfWeek={0}
          />

          <DatePicker
            id="birthday"
            hintText="Birthday"
            floatingLabelText="Birthday"
            container="inline"
            onChange={this.handleBirthday}
            textFieldStyle={{ width: "100%" }}
            value={this.state.data.birthday}
            locale="en-US"
            firstDayOfWeek={0}
          />

          {this.props.Auth.edit_finance && <>
            <TextField
              id="nextyeardepo"
              hintText="Next Year Deposit"
              floatingLabelText="Next Year Deposit"
              fullWidth={true}
              value={this.state.data.nextyeardepo}
              onChange={this.handleChange}
            />

            <TextField
              id="futuredepo"
              hintText="Future Months Deposit"
              floatingLabelText="Future Months Deposit"
              fullWidth={true}
              value={this.state.data.futuredepo}
              onChange={this.handleChange}
            />
          </>}


          <DatePicker
            id="startdate"
            hintText="Start Date"
            floatingLabelText="Start Date"
            container="inline"
            onChange={this.handleStartDate}
            textFieldStyle={{ width: "100%" }}
            value={this.state.data.startdate}
            locale="en-US"
            firstDayOfWeek={0}
          />

          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <DatePicker
              id="dropdate"
              hintText="Drop Date"
              floatingLabelText="Drop Date"
              container="inline"
              onChange={this.handleDropDate}
              value={this.state.data.dropdate}
              locale="en-US"
              firstDayOfWeek={0}
            />
            <IconButton tooltip="Clear" onClick={this.handleRemoveDropDate}>
              <ContentClear color={blue600} />
            </IconButton>
          </div>

          <Checkbox
            id="photo"
            label="Photo Permission"
            style={{ marginBottom: 16 }}
            defaultChecked={this.state.data.usephoto}
            onCheck={this.usePhoto}
          />
          <Checkbox
            id="acs"
            label="ACS"
            style={{ marginBottom: 16 }}
            defaultChecked={this.state.data.acs}
            onCheck={this.setACS}
          />

        </div>

        <div>
          <TextField
            hintText="School Address (for Afterschool Program)"
            floatingLabelText="School Address (for Afterschool Program)"
            fullWidth
            value={this.state.data.afterschool}
            onChange={this.handleAfterschool}
          />
          <div>
            <TextField
              hintText="Notes"
              floatingLabelText="Notes"
              fullWidth
              multiLine
              rows={3}
              value={this.state.data.memo}
              onChange={this.handleMemo}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary={true}
            onClick={this.saveData}
          />
          <RaisedButton
            label="Cancel"
            primary={true}
            onClick={this.goPreviousPage}
          />
          <RaisedButton
            label="Delete"
            secondary={true}
            onClick={this.handleOpen}
          />
        </div>
        <hr />
        <div className="programsFilter">
          <h3>Programs</h3>
          <div>
            <TextField
              floatingLabelText="Filter by name"
              fullWidth={true}
              value={this.state.programFilterValue}
              onChange={(event) => {this.setState({programFilterValue: event.target.value})}}
            />
          </div>
        </div>
        <Table>
          <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
            <TableRow>
              <TableHeaderColumn>Name</TableHeaderColumn>
              <TableHeaderColumn style={{ width: 50 }}>Price</TableHeaderColumn>
              <TableHeaderColumn style={{ width: 50 }}>
                Active
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            {this.state.plans
              .filter(row => row.name.toLowerCase().includes(this.state.programFilterValue))
              .map(row => {
                return (
                  <TableRow key={row.id}>
                    <TableRowColumn>{row.name}</TableRowColumn>
                    <TableRowColumn style={{ width: 50 }}>
                      {row.price}
                    </TableRowColumn>
                    <TableRowColumn style={{ width: 50 }}>
                      <Toggle
                        key={row.id}
                        name={row.id.toString()}
                        defaultToggled={row.toggled}
                        onToggle={this.handleToggle.bind(this)}
                      />
                    </TableRowColumn>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            gap: 10,
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20
          }}
        >
          <RaisedButton
            label="Save"
            primary={true}
            onClick={this.saveData}
          />
          <RaisedButton
            label="Cancel"
            primary={true}
            onClick={this.goPreviousPage}
          />
          <RaisedButton
            label="Delete"
            secondary={true}
            onClick={this.handleOpen}
          />
        </div>

        <Dialog
          actions={actions}
          modal={false}
          contentStyle={{ width: "20%", maxWidth: "none" }}
          open={this.state.opendialog}
          onRequestClose={this.handleClose}
        >
          Delete child?
        </Dialog>

        { transferModal }
      </div>
    );
  }
}

export default inject("Auth")(observer(EditChild))
